import React from 'react'
import { Link } from 'gatsby'
import { CaretLeft, CaretRight } from 'phosphor-react'

const Pagination = ({ pageContext }) => {
  const { previousPagePath, nextPagePath } = pageContext

  const nextPath = `${nextPagePath}/`
  const prevPath =
    previousPagePath === '/blog/' ? previousPagePath : `${previousPagePath}/`
  return (
    <nav
      className="pagination px-8 md:px-20 pb-10 max-w-screen-xl mx-auto text-center flex items-center justify-center"
      role="navigation"
    >
      <div className="navbar navbar-menu flex gap-x-4">
        {previousPagePath && (
          <div className="navbar-item">
            <Link to={prevPath} rel="prev" className="flex items-center">
              <CaretLeft />
              Prev
            </Link>
          </div>
        )}
        {nextPagePath && (
          <div className="navbar-item">
            <Link
              to={nextPath}
              rel="next"
              className="flex items-center justify-center"
            >
              Next
              <CaretRight />
            </Link>
          </div>
        )}
      </div>
    </nav>
  )
}

export default Pagination
