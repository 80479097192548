import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import PostList from '../components/PostList'
import Pagination from '../components/Pagination'
import PageTitle from '../components/Blocks/PageTitle'
import Seo from '../components/Seo'

const blog = ({ data, pageContext }) => {
  const {
    allWpPost: { edges },
  } = data

  return (
    <Layout pageContext={pageContext}>
      <Helmet>
        <title>
          D&M News | Personal & Commercial Auto Leasing | D&M Auto Leasing
        </title>
        <meta
          property="og:title"
          content="D&M News | Personal & Commercial Auto Leasing | D&M Auto Leasing"
        />
        <meta
          property="og:description"
          content="Click here to read the latest news, tips, and information on buying, selling, leasing, and maintaining your automobile from the experts at D&M Auto Leasing."
        />
        <meta
          property="description"
          content="Click here to read the latest news, tips, and information on buying, selling, leasing, and maintaining your automobile from the experts at D&M Auto Leasing."
        />
        <script type="application/ld+json">
          {`[
            ({
              '@context': 'https://schema.org',
              '@graph': [
                {
                  '@context': 'https://schema.org',
                  '@type': 'SiteNavigationElement',
                  '@id': 'https://www.dmautoleasing.com/#Main Menu',
                  name: 'Home',
                  url: 'https://www.dmautoleasing.com/',
                },
              ],
            },
            { '@context': 'https://schema.org', '@type': 'Article' },
            {
              '@context': 'https://schema.org',
              '@type': 'Review',
              '@id':
                'https://www.dmautoleasing.com/blog/dealerrater-dealer-of-the-year/#Review',
              itemReviewed: {
                '@type': 'LocalBusiness',
                image: {
                  '@type': 'ImageObject',
                  url:
                    'https://www.dmautoleasing.com/wp-content/uploads/2021/09/Dealer-Rater-Award_Banner-copy.jpg',
                  width: 1440,
                  height: 400,
                },
              },
              name: 'D&amp;M Auto Leasing',
              author: { '@type': 'Person', name: 'Chris Miller' },
              image: [
                {
                  '@type': 'ImageObject',
                  '@id':
                    'https://www.dmautoleasing.com/blog/dealerrater-dealer-of-the-year/#primaryimage',
                  url:
                    'https://www.dmautoleasing.com/wp-content/uploads/2021/09/Dealer-Rater-Award_Banner-copy-1200x900.jpg',
                  width: '1200',
                  height: '900',
                },
                {
                  '@type': 'ImageObject',
                  url:
                    'https://www.dmautoleasing.com/wp-content/uploads/2021/09/Dealer-Rater-Award_Banner-copy-1200x720.jpg',
                  width: '1200',
                  height: '720',
                },
                {
                  '@type': 'ImageObject',
                  url:
                    'https://www.dmautoleasing.com/wp-content/uploads/2021/09/Dealer-Rater-Award_Banner-copy-1200x675.jpg',
                  width: '1200',
                  height: '675',
                },
                {
                  '@type': 'ImageObject',
                  url:
                    'https://www.dmautoleasing.com/wp-content/uploads/2021/07/award-3914-9-255x300.png',
                  width: 255,
                  height: 300,
                },
                {
                  '@type': 'ImageObject',
                  url:
                    'https://www.dmautoleasing.com/wp-content/uploads/2021/07/award-3914-10-255x300.png',
                  width: 255,
                  height: 300,
                },
                {
                  '@type': 'ImageObject',
                  url:
                    'https://www.dmautoleasing.com/wp-content/uploads/2021/06/DealerRater2021-e1625094150980.png',
                  width: 200,
                  height: 200,
                },
                {
                  '@type': 'ImageObject',
                  url:
                    'https://www.dmautoleasing.com/wp-content/uploads/2021/09/award-3914-2-255x300.png',
                  width: 255,
                  height: 300,
                },
                {
                  '@type': 'ImageObject',
                  url:
                    'https://www.dmautoleasing.com/wp-content/uploads/2021/09/award-3914-14-255x300.png',
                  width: 255,
                  height: 300,
                },
                {
                  '@type': 'ImageObject',
                  url:
                    'https://www.dmautoleasing.com/wp-content/uploads/2021/09/award-3914-15.png',
                  width: 250,
                  height: 250,
                },
                {
                  '@type': 'ImageObject',
                  url:
                    'https://www.dmautoleasing.com/wp-content/uploads/2021/09/award-3914-3-255x300.png',
                  width: 255,
                  height: 300,
                },
                {
                  '@type': 'ImageObject',
                  url:
                    'https://www.dmautoleasing.com/wp-content/uploads/2021/09/award-3914-16-255x300.png',
                  width: 255,
                  height: 300,
                },
                {
                  '@type': 'ImageObject',
                  url:
                    'https://www.dmautoleasing.com/wp-content/uploads/2021/09/award-3914-17.png',
                  width: 250,
                  height: 250,
                },
                {
                  '@type': 'ImageObject',
                  url:
                    'https://www.dmautoleasing.com/wp-content/uploads/2021/09/award-3914-4-255x300.png',
                  width: 255,
                  height: 300,
                },
                {
                  '@type': 'ImageObject',
                  url:
                    'https://www.dmautoleasing.com/wp-content/uploads/2021/09/award-3914-18-255x300.png',
                  width: 255,
                  height: 300,
                },
                {
                  '@type': 'ImageObject',
                  url:
                    'https://www.dmautoleasing.com/wp-content/uploads/2021/09/award-3914-19.png',
                  width: 257,
                  height: 257,
                },
                {
                  '@type': 'ImageObject',
                  url:
                    'https://www.dmautoleasing.com/wp-content/uploads/2021/09/award-3914-5-255x300.png',
                  width: 255,
                  height: 300,
                },
                {
                  '@type': 'ImageObject',
                  url:
                    'https://www.dmautoleasing.com/wp-content/uploads/2021/09/award-3914-20-255x300.png',
                  width: 255,
                  height: 300,
                },
                {
                  '@type': 'ImageObject',
                  url:
                    'https://www.dmautoleasing.com/wp-content/uploads/2021/09/award-3914-21.png',
                  width: 257,
                  height: 257,
                },
                {
                  '@type': 'ImageObject',
                  url:
                    'https://www.dmautoleasing.com/wp-content/uploads/2021/09/award-3914-6-255x300.png',
                  width: 255,
                  height: 300,
                },
                {
                  '@type': 'ImageObject',
                  url:
                    'https://www.dmautoleasing.com/wp-content/uploads/2021/09/award-3914-22-255x300.png',
                  width: 255,
                  height: 300,
                },
                {
                  '@type': 'ImageObject',
                  url:
                    'https://www.dmautoleasing.com/wp-content/uploads/2021/09/award-3914-23.png',
                  width: 257,
                  height: 257,
                },
                {
                  '@type': 'ImageObject',
                  url:
                    'https://www.dmautoleasing.com/wp-content/uploads/2021/09/award-3914-7-255x300.png',
                  width: 255,
                  height: 300,
                },
                {
                  '@type': 'ImageObject',
                  url:
                    'https://www.dmautoleasing.com/wp-content/uploads/2021/09/award-3914-24-255x300.png',
                  width: 255,
                  height: 300,
                },
                {
                  '@type': 'ImageObject',
                  url:
                    'https://www.dmautoleasing.com/wp-content/uploads/2021/09/award-3914-25.png',
                  width: 257,
                  height: 257,
                },
              ],
            },
            {
              '@context': 'https://schema.org',
              '@type': 'Organization',
              '@id': 'https://www.dmautoleasing.com#Organization',
              name: 'D&amp;M Auto Leasing',
              url: 'https://www.dmautoleasing.com',
              sameAs: [],
            })
          ]`}
        </script>
      </Helmet>
      <PageTitle title="Blog" />
      <PostList posts={edges} />
      <Pagination pageContext={pageContext} pathPrefix="/" />
    </Layout>
  )
}

export default blog

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    allWpPost(sort: { fields: date, order: DESC }, limit: $limit, skip: $skip) {
      edges {
        node {
          id
          showReview {
            showReviewSchema
          }
          title
          slug
          content
          excerpt
          uri
          author {
            node {
              name
            }
          }
          featuredImage {
            node {
              mediaItemUrl
              mediaDetails {
                height
                width
              }
            }
          }
          date(formatString: "MMMM DD, YYYY")
          categories {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
  }
`
